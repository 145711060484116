import moment from 'moment';
import { Ref, ref, watch } from 'vue';

import { getShipsMeanTime } from '#/compositions/useProtocols';
import { normalizeTimestamp } from '#/utilities';

export interface FilterType {
  dateRange: Ref<moment.Moment[]>;
  authorToFilter: Ref<string | null>;
  voyageToFilter: Ref<string | null>;
  searchText: Ref<string>;
}

export const ALL_DATES_RANGE = [moment.utc(new Date(0)).startOf('day'), moment.utc().add(100, 'year').endOf('year')];

const filterMap = new Map<string, FilterType>();

export const useFilters = (
  filterName: Ref<string> | undefined,
  options?: { startWith?: 'all_dates' | 'day' | 'month' },
): FilterType => {
  const shipsMeanTime = getShipsMeanTime();
  const startWith = options?.startWith || 'day';
  const dateRange = ref(
    startWith === 'all_dates'
      ? ALL_DATES_RANGE
      : [
          normalizeTimestamp(shipsMeanTime.value).startOf(startWith),
          normalizeTimestamp(shipsMeanTime.value).endOf(startWith),
        ],
  );

  watch(
    shipsMeanTime,
    (value, oldValue) => {
      if (startWith !== 'all_dates' && oldValue === undefined && value !== undefined) {
        dateRange.value = [normalizeTimestamp(value).startOf(startWith), normalizeTimestamp(value).endOf(startWith)];
      }
    },
    { immediate: true },
  );

  const newFilter = {
    dateRange,
    authorToFilter: ref(null),
    voyageToFilter: ref(null),
    searchText: ref(''),
  };
  if (!filterName) {
    return newFilter;
  }
  let filter = filterMap.get(filterName.value);
  if (filter === undefined) {
    filter = newFilter;
    filterMap.set(filterName.value, newFilter);
  }
  return filter;
};
