<template>
  <TextField
    v-model="dateTimeFormatted"
    :title="title"
    :title-hover="titleHover"
    :placeholder="internalPlaceholder"
    :mark="dateInvalid || mark"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import moment from 'moment';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';

import TextField from '#/components/TextField.vue';
import { MOMENT_DATE_TIME, MOMENT_FORMATS } from '#/config/time';
import { format } from '#/utilities';

export default defineComponent({
  name: 'DateTimeField',

  components: {
    TextField,
  },

  inheritAttrs: false,

  props: {
    // eslint-disable-next-line vue/require-default-prop
    title: {
      type: String,
      required: false,
    },
    titleHover: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: String,
    },
    format: {
      type: String as PropType<keyof typeof MOMENT_FORMATS | undefined>,
      default: undefined,
    },

    customFormat: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    required: {
      type: Boolean,
    },
    disableRounding: {
      type: Boolean,
    },
    mark: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string | undefined): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_valid: boolean): boolean => true,
  },

  setup(props, context) {
    const propFormat = toRef(props, 'format');
    const customFormat = toRef(props, 'customFormat');
    const placeholder = toRef(props, 'placeholder');
    const value = toRef(props, 'value');
    const required = toRef(props, 'required');
    const disableRounding = toRef(props, 'disableRounding');

    const dateInvalid = ref<boolean>(false);

    const momentFormat = computed<string>(() => {
      if (propFormat.value) {
        return MOMENT_FORMATS[propFormat.value];
      } else if (customFormat.value) {
        return customFormat.value;
      }
      return MOMENT_DATE_TIME;
    });
    const internalPlaceholder = computed<string>(() => {
      if (placeholder.value) {
        return placeholder.value;
      }
      return momentFormat.value;
    });

    const timezoneOffset = computed(() => moment.parseZone(value.value).utcOffset());
    const dateTimeFormatted = computed<string>({
      get() {
        if (!value.value) {
          return '';
        }
        return format(value.value, momentFormat.value, disableRounding.value);
      },

      set(date: string) {
        if (!required.value && date === '') {
          dateInvalid.value = false;
          context.emit('input', undefined);
          context.emit('invalid', false);
          return;
        }
        let newDate = moment(date, momentFormat.value, true);
        if (!newDate.isValid()) {
          newDate = moment(moment(date, 'DD-MM-YYYY HH:mm', true), momentFormat.value, true);
        }
        dateInvalid.value = !newDate.isValid();
        if (!dateInvalid.value) {
          context.emit(
            'input',
            import.meta.env.VITE_SMT_HANDLING_ENABLED
              ? newDate.utcOffset(timezoneOffset.value, true).format()
              : newDate.utc().format(),
          );
        }
        context.emit('invalid', dateInvalid.value);
      },
    });

    return {
      dateInvalid,
      dateTimeFormatted,
      internalPlaceholder,
    };
  },
});
</script>
