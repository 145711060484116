import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { schemaToDetails } from '#/components/protocol/ProtocolUIConfigDetails';

import { checkForNewPage, drawTableBorder, writeTableTitle } from './helpers';
import { addDetails } from './pdfExportProtocol';
import * as style from './style';
import { ViewDataForPdfTable } from './types';

export function insertViewTable(
  doc: jsPDF,
  tableData: ViewDataForPdfTable,
  cursorPosition: { x: number; y: number },
  opts: { documentWidth: number; documentHeight: number },
  columnWidths: number[] = [],
): { x: number; y: number } {
  let pageCounterOthers = 1;
  cursorPosition.y = checkForNewPage(doc, cursorPosition.y, opts.documentHeight);
  writeTableTitle(doc, {
    y: cursorPosition.y + style.TABLES_DATA_VERTICAL_DISTANCE,
    title: tableData.title,
    documentWidth: opts.documentWidth,
  });
  cursorPosition.y = cursorPosition.y + style.TABLES_DATA_VERTICAL_DISTANCE + style.TABLE_DATA_TITLE_HEIGHT;

  if (tableData.equipmentHistory) {
    const equipmentItemDetails = schemaToDetails(
      tableData.equipmentHistory.equipmentItemSchema,
      tableData.equipmentHistory.equipmentItem.itemData,
    );
    cursorPosition.y = addDetails(doc, equipmentItemDetails, cursorPosition.y, true, opts);
  }

  const tableWidth = opts.documentWidth - style.CONTENT_MARGIN_X;
  const summedColumnWidths = columnWidths.reduce((sum, width) => sum + width, 0);
  const fallBackColumnWidth = (tableWidth - summedColumnWidths) / (tableData.header.length - columnWidths.length);
  if (tableData.entries.length) {
    autoTable(doc, {
      head: [tableData.header],
      body: tableData.entries
        .map((entry) => {
          return [Object.values(entry).flatMap((content) => ({ content: `${content ?? ''}`, rowSpan: 1 }))];
        })
        .reduce((allRows, rows) => [...allRows, ...rows], []),
      columnStyles: tableData.header.reduce<Record<number, { cellWidth: number }>>(
        (allColumnStyles, _, index) => ({
          ...allColumnStyles,
          [index]: { cellWidth: columnWidths[index] || fallBackColumnWidth },
        }),
        {},
      ),
      didDrawPage: (data) => {
        data.table.settings.margin = {
          ...data.table.settings.margin,
          top: style.TABLE_DATA_Y_NEW_PAGE + style.TABLE_DATA_TITLE_HEIGHT,
        };
        if (pageCounterOthers > 1) {
          writeTableTitle(doc, { y: style.TABLE_DATA_Y_NEW_PAGE, title: tableData.title });
        }
        pageCounterOthers++;
      },
      didDrawCell: (data) => {
        drawTableBorder(doc, {
          x: data.cell.x,
          y: data.cell.y,
          width: data.cell.width,
          color: style.TABLE_DATA_BORDER_COLOR,
        });
        cursorPosition = data.cursor as { x: number; y: number };
      },
      headStyles: {
        textColor: style.TABLE_DATA_HEADER_FONT_COLOR,
        fontSize: style.TABLE_DATA_HEADER_FONT_SIZE,
        font: style.TABLE_DATA_HEADER_FONT_TYPE,
      },
      margin: { left: style.CONTENT_MARGIN_LEFT, bottom: style.TABLE_DATA_MARGIN_BOTTOM },
      tableWidth,
      theme: 'plain',
      startY: cursorPosition.y,
      styles: {
        font: style.TABLE_DATA_CONTENT_FONT_TYPE,
        fontSize: style.TABLE_DATA_CONTENT_FONT_SIZE,
        textColor: style.TABLE_DATA_CONTENT_FONT_COLOR,
      },
      pageBreak: 'auto',
      rowPageBreak: 'auto',
    });
  } else {
    doc.setFontSize(style.TABLE_DATA_NOT_AVAILABLE_FONT_SIZE);
    doc.setFont(style.TABLE_DATA_NOT_AVAILABLE_FONT_TYPE);
    doc.setTextColor(style.TABLE_DATA_NOT_AVAILABLE_FONT_COLOR);
    const y = cursorPosition.y + style.TABLE_DATA_NOT_AVAILABLE_TEXT_MARGIN_TOP;
    doc.text('No records available', opts.documentWidth / 2, y, {
      align: 'center',
      baseline: 'middle',
    });
    cursorPosition = {
      x: 0,
      y,
    };
  }
  return cursorPosition;
}
