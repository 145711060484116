import jsPDF from 'jspdf';
import autoTable, { applyPlugin } from 'jspdf-autotable';

import { drawTableBorder, writeTableTitle } from './helpers';
import * as style from './style';
import type { AuditLogDataForPDFTable } from './types';

applyPlugin(jsPDF);

export const insertAuditLogTable = (
  doc: jsPDF,
  dataForOneDay: AuditLogDataForPDFTable,
  cursorPosition: { x: number; y: number },
  onlyTimeColumn: boolean,
): void => {
  let pageCounterOthers = 1;
  const title = 'Audit Logs';
  cursorPosition = { ...cursorPosition, y: cursorPosition.y + style.TABLES_DATA_VERTICAL_DISTANCE };
  writeTableTitle(doc, { y: cursorPosition.y, title });
  if (dataForOneDay.auditLogs.length) {
    autoTable(doc, {
      head: [[onlyTimeColumn ? 'Time' : 'Date', 'Type', 'Details', 'Author']],
      body: dataForOneDay.auditLogs.map((auditLog) => Object.values(auditLog)),
      columnStyles: {
        0: {
          cellWidth: style.TABLE_DATA_COLUMN_TIME_WIDTH + (onlyTimeColumn ? 0 : style.TABLE_DATA_COLUMN_DATE_WIDTH),
        },
        1: { cellWidth: style.TABLE_DATA_COLUMN_TYPE_WIDTH },
        2: {
          cellWidth:
            style.TABLE_AUDIT_LOGS_COLUMN_DETAILS_WIDTH - (onlyTimeColumn ? 0 : style.TABLE_DATA_COLUMN_DATE_WIDTH),
        },
        3: { cellWidth: style.TABLE_AUDIT_LOGS_COLUMN_AUTHOR_WIDTH },
      },
      didDrawPage: (data) => {
        data.table.settings.margin = {
          ...data.table.settings.margin,
          top: style.TABLE_DATA_Y_NEW_PAGE + style.TABLE_DATA_TITLE_HEIGHT,
        };
        if (pageCounterOthers > 1) {
          writeTableTitle(doc, { y: style.TABLE_DATA_Y_NEW_PAGE, title });
        }
        pageCounterOthers++;
      },
      didDrawCell: (data) => {
        drawTableBorder(doc, {
          x: data.cell.x,
          y: data.cell.y,
          width: data.cell.width,
          color: style.TABLE_DATA_BORDER_COLOR,
        });
      },
      headStyles: {
        textColor: style.TABLE_DATA_HEADER_FONT_COLOR,
        fontSize: style.TABLE_DATA_HEADER_FONT_SIZE,
        font: style.TABLE_DATA_HEADER_FONT_TYPE,
      },
      margin: { left: style.CONTENT_MARGIN_LEFT, bottom: style.TABLE_DATA_MARGIN_BOTTOM },
      tableWidth: style.DOCUMENT_WIDTH - style.CONTENT_MARGIN_X,
      theme: 'plain',
      startY: cursorPosition.y + style.TABLE_DATA_TITLE_HEIGHT,
      styles: {
        font: style.TABLE_DATA_CONTENT_FONT_TYPE,
        fontSize: style.TABLE_DATA_CONTENT_FONT_SIZE,
        textColor: style.TABLE_DATA_CONTENT_FONT_COLOR,
      },
    });
  } else {
    doc.setFontSize(style.TABLE_DATA_NOT_AVAILABLE_FONT_SIZE);
    doc.setFont(style.TABLE_DATA_NOT_AVAILABLE_FONT_TYPE);
    doc.setTextColor(style.TABLE_DATA_NOT_AVAILABLE_FONT_COLOR);
    doc.text(
      'No logs available',
      style.DOCUMENT_WIDTH / 2,
      cursorPosition.y + style.TABLE_DATA_NOT_AVAILABLE_TEXT_MARGIN_TOP,
      {
        align: 'center',
        baseline: 'middle',
      },
    );
  }
};
