<template>
  <div class="wrapper">
    <div @click="openDateRangePicker">
      <Icon name="action/calendar" class="ma-1" color="primary" />
    </div>
    <v-md-date-range-picker
      ref="dateRangePicker"
      class="dateRangePicker white gray_first--text mr-1"
      :opens="opens"
      :min-year="`${minYear}`"
      :max-year="`${maxYear}`"
      :start-date="dateRange[0].format('YYYY-MM-DD')"
      :end-date="dateRange[1].format('YYYY-MM-DD')"
      :presets="presets"
      @change="filterDates"
    >
      <div slot="input" data-test="dateRange" class="selectedRangeField">{{ dateRangeLabel }}</div>
    </v-md-date-range-picker>
    <button
      class="flex w-[33px] h-8 items-center justify-center border-l border-solid border-l-primary"
      :class="{ 'cursor-pointer': !allDatesShown }"
      :disabled="allDatesShown"
      @click="changeRange('prev')"
      data-test="btn-previous-date"
    >
      <Icon name="arrow/chevronLeft" :color="allDatesShown ? 'gray_fourth' : 'primary'" />
    </button>
    <button
      class="flex w-[33px] h-8 items-center justify-center border-l border-solid border-l-primary"
      :class="{ 'cursor-pointer': !allDatesShown }"
      :disabled="allDatesShown"
      @click="changeRange('next')"
      data-test="btn-next-date"
    >
      <Icon name="arrow/chevronRight" :color="allDatesShown ? 'gray_fourth' : 'primary'" />
    </button>
  </div>
</template>

<script lang="ts">
import { isEqual } from 'lodash';
import moment from 'moment';
import { VMdCalendar, VMdDateRangePicker } from 'v-md-date-range-picker';
import { computed, defineComponent, nextTick, PropType, ref, toRef, watch } from 'vue';

import { ALL_DATES_RANGE } from '#/compositions/useFilters';
import { getShipsMeanTime } from '#/compositions/useProtocols';
import { MOMENT_DATE } from '#/config/time';
import i18n from '#/i18n';
import { normalizeTimestamp } from '#/utilities';

import Icon from './Icon.vue';

export default defineComponent({
  name: 'DateRangePicker',

  components: {
    Icon,
  },

  props: {
    dateRange: {
      type: Array as PropType<moment.Moment[]>,
      required: true,
    },
    opens: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'right',
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'filter-dates': (_valid: moment.Moment[]): boolean => true,
  },

  setup(props, { emit }) {
    const dateRange = toRef(props, 'dateRange');

    const dateRangePicker = ref<VMdDateRangePicker>();

    const minYear = computed(() => ALL_DATES_RANGE[0].year());
    const maxYear = computed(() => ALL_DATES_RANGE[1].year());

    const shipsMeanTime = getShipsMeanTime();

    const presets = computed(() => {
      return [
        {
          label: i18n.tc('date_picker.today'),
          range: [
            moment(shipsMeanTime.value).subtract(0, 'day').startOf('day'),
            moment(shipsMeanTime.value).subtract(0, 'day').endOf('day'),
          ],
        },
        {
          label: i18n.tc('date_picker.yesterday'),
          range: [
            moment(shipsMeanTime.value).subtract(1, 'day').startOf('day'),
            moment(shipsMeanTime.value).subtract(1, 'day').endOf('day'),
          ],
        },
        {
          label: i18n.tc('date_picker.this_week'),
          range: [moment(shipsMeanTime.value).startOf('isoWeek'), moment(shipsMeanTime.value).endOf('isoWeek')],
        },
        {
          label: i18n.tc('date_picker.last_week'),
          range: [
            moment(shipsMeanTime.value).subtract(1, 'week').startOf('isoWeek'),
            moment(shipsMeanTime.value).subtract(1, 'week').endOf('isoWeek'),
          ],
        },
        {
          label: i18n.tc('date_picker.this_month'),
          range: [
            moment(shipsMeanTime.value).subtract(0, 'month').startOf('month'),
            moment(shipsMeanTime.value).subtract(0, 'month').endOf('month'),
          ],
        },
        {
          label: i18n.tc('date_picker.last_month'),
          range: [
            moment(shipsMeanTime.value).subtract(1, 'month').startOf('month'),
            moment(shipsMeanTime.value).subtract(1, 'month').endOf('month'),
          ],
        },
        {
          label: i18n.tc('date_picker.this_year'),
          range: [moment(shipsMeanTime.value).startOf('year'), moment(shipsMeanTime.value).endOf('year')],
        },
        {
          label: i18n.tc('date_picker.last_year'),
          range: [
            moment(shipsMeanTime.value).subtract(1, 'year').startOf('year'),
            moment(shipsMeanTime.value).subtract(1, 'year').endOf('year'),
          ],
        },
        {
          label: i18n.tc('date_picker.all_dates'),
          range: ALL_DATES_RANGE,
        },
      ];
    });

    const allDatesShown = computed(() => {
      return isEqual(
        dateRange.value.map((d) => d.format('X')),
        ALL_DATES_RANGE.map((d) => d.format('X')),
      );
    });

    const sameDay = computed(() => {
      return dateRange.value[0].isSame(dateRange.value[1], 'date');
    });

    const dateRangeLabel = computed(() => {
      if (allDatesShown.value) {
        return i18n.tc('date_picker.all_dates');
      }
      if (sameDay.value) {
        return dateRange.value[0].format(MOMENT_DATE);
      }
      return dateRange.value.map((d) => d.format(MOMENT_DATE)).join(' - ');
    });

    watch(
      [allDatesShown, dateRangePicker],
      () => {
        void adjustCalendarWhenAllDatesShown();
      },
      { immediate: true },
    );

    async function adjustCalendarWhenAllDatesShown(): Promise<void> {
      if (!allDatesShown.value || !dateRangePicker.value) {
        return;
      }

      await nextTick();

      const calendarElements = dateRangePicker.value.$children.filter((child): child is VMdCalendar =>
        child.$el.className.includes('calendar-table'),
      );
      calendarElements.forEach((calendarElement) => (calendarElement.activeYear = moment().year()));
      dateRangePicker.value.leftCalendarMonth_ = moment();
    }

    function openDateRangePicker(): void {
      dateRangePicker.value?.togglePicker();
    }

    function filterDates(values: moment.Moment[]): void {
      emit('filter-dates', [normalizeTimestamp(values[0]).startOf('day'), normalizeTimestamp(values[1]).endOf('day')]);
    }

    function changeRange(direction: string): void {
      if (dateRange.value[0] !== ALL_DATES_RANGE[0]) {
        const daysDiff = dateRange.value[1].diff(dateRange.value[0], 'days') + 1;
        let newRange;
        if (direction === 'next') {
          newRange = dateRange.value.map((d) => d.add(daysDiff, 'days'));
        } else {
          newRange = dateRange.value.map((d) => d.subtract(daysDiff, 'days'));
        }
        filterDates(newRange);
      }
    }

    return {
      presets,
      allDatesShown,
      dateRangeLabel,
      minYear,
      maxYear,
      changeRange,
      filterDates,
      openDateRangePicker,
      dateRangePicker,
    };
  },
});
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--v-primary-base) !important;
  box-sizing: border-box;
  border-radius: 2px;
  height: 34px;
  background: var(--v-white-base);
  cursor: pointer;
}

.selectedRangeField {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  padding-top: 4px;
  margin: 0 3px;
  text-align: center;
  font-size: 16px;
}

.dateRangePicker ::v-deep .mdrp__panel {
  top: 31px !important;
  background: var(--v-white-base);
  color: var(--v-gray_first-base) !important;
  border-color: var(--v-primary-base) !important;
  width: auto !important;
}

.dateRangePicker ::v-deep .mdrp__panel.opens-arrow-pos-right {
  right: -71px !important;
}

.dateRangePicker ::v-deep .mdrp__panel.opens-arrow-pos-center {
  left: 50% !important;
  transform: translateX(-50%);
}

.dateRangePicker ::v-deep .mdrp__panel::before,
.dateRangePicker ::v-deep .mdrp__panel::after {
  border-bottom-color: var(--v-primary-base) !important;
}

.dateRangePicker ::v-deep .mdrp__panel.opens-arrow-pos-right::before,
.dateRangePicker ::v-deep .mdrp__panel.opens-arrow-pos-right::after {
  right: 81px !important;
}

.dateRangePicker ::v-deep td.calendar-cell:not(.off) {
  color: var(--v-gray_first-base) !important;
}

.dateRangePicker ::v-deep td.calendar-cell.off {
  color: var(--v-gray_fourth-base) !important;
  background-color: var(--v-white-base) !important;
}

.dateRangePicker ::v-deep td.calendar-cell.in-range {
  background: var(--v-primary_bg-base) !important;
}

.dateRangePicker ::v-deep td.calendar-cell.active > div {
  background: var(--v-primary-base) !important;
  color: var(--v-gray_first-base) !important;
}

.dateRangePicker ::v-deep td.calendar-cell.off > div {
  background-color: var(--v-white-base) !important;
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul > li.active {
  color: var(--v-gray_first-base);
  background-color: var(--v-primary_bg-base) !important;
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul > li.yearRangeElement {
  box-sizing: border-box;
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul > li.yearRangeElement:hover {
  background-color: var(--v-white-base);
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul > li.yearRangeElement:active {
  color: var(--v-primary-base);
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul {
  padding-top: 42px;
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul > li {
  padding-top: 2.3px;
}

.dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul > li:hover {
  background: var(--v-gray_fifth-base) !important;
}

.dateRangePicker ::v-deep tr > td.calendar-cell:hover {
  background: var(--v-gray_fifth-base) !important;
}

@media only screen and (max-width: 674px) {
  .dateRangePicker ::v-deep .mdrp__panel.show-calendar {
    display: block !important;
  }

  .dateRangePicker ::v-deep .mdrp__panel .calendar-table {
    padding: 0 8px 8px !important;
  }

  .dateRangePicker ::v-deep div.mdrp__panel > div.ranges > ul {
    width: unset;
    padding-top: 0;
  }
}
</style>
