// General Styles
// ------ COLORS ------
const FOOTER_FONT_COLOR = 'black';
const FOLDING_MARKS_COLOR = [128, 128, 128];
const PERFORATE_MARK_COLOR = [128, 128, 128];
// ------ DIMENSIONS ------
const DOCUMENT_WIDTH = 210;
const DOCUMENT_HEIGHT = 297;
const DOCUMENT_TITLE_Y = 13.5;
const CONTENT_MARGIN_LEFT = 20;
const CONTENT_MARGIN_RIGHT = 10;
const CONTENT_MARGIN_X = CONTENT_MARGIN_LEFT + CONTENT_MARGIN_RIGHT;
const CELL_PADDING_TOP = 2;
const LINE_AND_BORDER_WIDTH = 0.25;
const FOLDING_MARKS_Y = [87, 192];
const FOLDING_MARKS_WIDTH = 0.1;
const FOLDING_MARKS_LENGTH = 10;
const PERFORATE_MARK_Y = 148.5;
const PERFORATE_MARK_WIDTH = 0.1;
const PERFORATE_MARK_LENGTH = 15;
const FOOTER_Y_OFFSET_BOTTOM = 17;
const LOGO_Y_OFFSET_BOTTOM = 20;
const LOGO_WIDTH = 24.08;
const LOGO_HEIGHT = 8.85;
// ------ FONT SIZES ------
const FOOTER_FONT_SIZE = 8;
// ------ FONT TYPES ------
const FOOTER_FONT_TYPE = 'MontserratRegular';
const UNDEFINED_FONT_TYPE = 'MontserratItalic';

// Page Title Styles
const PAGE_TITLE_FONT_TYPE = 'MontserratBold';
const PAGE_TITLE_FONT_SIZE = 18;
const PAGE_TITLE_FONT_COLOR = 'black';

// Separation Line Styles
const SEPARATION_LINE_MARGIN_LEFT = 20;
const SEPARATION_LINE_LENGTH = 200;
const SEPARATION_LINE_COLOR = [128, 128, 128];
const SEPARATION_LINE_TOP_FIRST_PAGE_COLOR = [0, 0, 0];
const SEPARATION_LINE_PAGE_TITLE_Y = 27;
const SEPARATION_LINE_FOOTER_Y_OFFSET_BOTTOM = 24;

// Protocol Separation Line Styles
const SEPARATION_LINE_PROTOCOLS_GENERAL_INFORMATION_Y = 87;

// Audit Log Separation Line Styles
const SEPARATION_LINE_AUDIT_LOGS_GENERAL_INFORMATION_Y = 67;

// General Information Table Styles
// ------ COLORS ------
const TABLE_INFORMATION_FONT_COLOR = 'black';
// ------ DIMENSIONS ------
const TABLE_INFORMATION_WIDTH = 180;
const TABLE_INFORMATION_LEFT_COLUMNS_WIDTH = 37;
const TABLE_INFORMATION_RIGHT_COLUMNS_WIDTH = 45.5;
const TABLE_INFORMATION_Y_START = 32;
const TABLES_INFORMATION_DISTANCE = 15;
// ------ FONT SIZES ------
const TABLE_INFORMATION_FONT_SIZE = 12;
// ------ FONT TYPES ------
const TABLE_INFORMATION_FONT_TYPE = 'MontserratRegular';
const TABLE_INFORMATION_VESSEL_FONT_TYPE = 'MontserratBold';

// Protocol / Audit Log Table Styles General
// ------ COLORS ------
const TABLE_DATA_TITLE_BACKGROUND_COLOR = '#C6D2D9';
const TABLE_DATA_HEADER_FONT_COLOR = '#888888';
const TABLE_DATA_NOT_AVAILABLE_FONT_COLOR = '#888888';
const TABLE_DATA_BORDER_COLOR = [128, 128, 128];
const TABLE_DATA_CONTENT_FONT_COLOR = 'black';
const TABLE_DATA_TITLE_FONT_COLOR = 'black';
const TABLE_DATA_DELETED_COLOR = 'black';
// ------ DIMENSIONS ------
const TABLE_DATA_TITLE_HEIGHT = 8;
const TABLE_DATA_HEADER_PADDING_LEFT = 2;
const TABLE_DATA_COLUMN_DATE_WIDTH = 16;
const TABLE_DATA_COLUMN_TIME_WIDTH = 12;
const TABLE_DATA_COLUMN_TYPE_WIDTH = 25;
const TABLE_DATA_COLUMN_CATEGORY_WIDTH = 30;
const TABLE_DATA_COLUMN_DETAILS_WIDTH = 72;
const TABLE_DATA_COLUMN_AUTHOR_WIDTH = 29;
const TABLE_DATA_COLUMN_APPROVAL_WIDTH = 10;
const TABLES_DATA_VERTICAL_DISTANCE = 8;
const TABLE_DATA_Y_NEW_PAGE = 20;
const TABLE_DATA_MARGIN_BOTTOM = 28;
const TABLE_DATA_NOT_AVAILABLE_TEXT_MARGIN_TOP = 4;
// ------ FONT SIZES ------
const TABLE_DATA_CONTENT_FONT_SIZE = 8;
const TABLE_DATA_TITLE_FONT_SIZE = 12;
const TABLE_DATA_HEADER_FONT_SIZE = 5;
const TABLE_DATA_NOT_AVAILABLE_FONT_SIZE = 10;
const TABLE_HISTORY_FONT_SIZE = 7;
// ------ FONT TYPES ------
const TABLE_DATA_NOT_AVAILABLE_FONT_TYPE = 'MontserratRegular';
const TABLE_DATA_CONTENT_FONT_TYPE = 'MontserratRegular';
const TABLE_DATA_HEADER_FONT_TYPE = 'MontserratRegular';
const TABLE_DATA_TITLE_FONT_TYPE = 'MontserratBold';
const TABLE_HISTORY_FONT_TYPE = 'MontserratItalic';

// Protocol Table Styles Navigation
const TABLE_NAVIGATION_COLUMN_POSITION_WIDTH = 22;
const TABLE_NAVIGATION_COLUMN_DETAILS_WIDTH = 105;

// Audit Log Table Styles
const TABLE_AUDIT_LOGS_COLUMN_AUTHOR_WIDTH = 29;
const TABLE_AUDIT_LOGS_COLUMN_DETAILS_WIDTH = 114;

export {
  CELL_PADDING_TOP,
  CONTENT_MARGIN_LEFT,
  CONTENT_MARGIN_RIGHT,
  CONTENT_MARGIN_X,
  DOCUMENT_HEIGHT,
  DOCUMENT_TITLE_Y,
  DOCUMENT_WIDTH,
  FOLDING_MARKS_COLOR,
  FOLDING_MARKS_LENGTH,
  FOLDING_MARKS_WIDTH,
  FOLDING_MARKS_Y,
  FOOTER_FONT_COLOR,
  FOOTER_FONT_SIZE,
  FOOTER_FONT_TYPE,
  FOOTER_Y_OFFSET_BOTTOM,
  LINE_AND_BORDER_WIDTH,
  LOGO_HEIGHT,
  LOGO_WIDTH,
  LOGO_Y_OFFSET_BOTTOM,
  PAGE_TITLE_FONT_COLOR,
  PAGE_TITLE_FONT_SIZE,
  PAGE_TITLE_FONT_TYPE,
  PERFORATE_MARK_COLOR,
  PERFORATE_MARK_LENGTH,
  PERFORATE_MARK_WIDTH,
  PERFORATE_MARK_Y,
  SEPARATION_LINE_AUDIT_LOGS_GENERAL_INFORMATION_Y,
  SEPARATION_LINE_COLOR,
  SEPARATION_LINE_FOOTER_Y_OFFSET_BOTTOM,
  SEPARATION_LINE_LENGTH,
  SEPARATION_LINE_MARGIN_LEFT,
  SEPARATION_LINE_PAGE_TITLE_Y,
  SEPARATION_LINE_PROTOCOLS_GENERAL_INFORMATION_Y,
  SEPARATION_LINE_TOP_FIRST_PAGE_COLOR,
  TABLE_AUDIT_LOGS_COLUMN_AUTHOR_WIDTH,
  TABLE_AUDIT_LOGS_COLUMN_DETAILS_WIDTH,
  TABLE_DATA_BORDER_COLOR,
  TABLE_DATA_COLUMN_APPROVAL_WIDTH,
  TABLE_DATA_COLUMN_AUTHOR_WIDTH,
  TABLE_DATA_COLUMN_CATEGORY_WIDTH,
  TABLE_DATA_COLUMN_DATE_WIDTH,
  TABLE_DATA_COLUMN_DETAILS_WIDTH,
  TABLE_DATA_COLUMN_TIME_WIDTH,
  TABLE_DATA_COLUMN_TYPE_WIDTH,
  TABLE_DATA_CONTENT_FONT_COLOR,
  TABLE_DATA_CONTENT_FONT_SIZE,
  TABLE_DATA_CONTENT_FONT_TYPE,
  TABLE_DATA_DELETED_COLOR,
  TABLE_DATA_HEADER_FONT_COLOR,
  TABLE_DATA_HEADER_FONT_SIZE,
  TABLE_DATA_HEADER_FONT_TYPE,
  TABLE_DATA_HEADER_PADDING_LEFT,
  TABLE_DATA_MARGIN_BOTTOM,
  TABLE_DATA_NOT_AVAILABLE_FONT_COLOR,
  TABLE_DATA_NOT_AVAILABLE_FONT_SIZE,
  TABLE_DATA_NOT_AVAILABLE_FONT_TYPE,
  TABLE_DATA_NOT_AVAILABLE_TEXT_MARGIN_TOP,
  TABLE_DATA_TITLE_BACKGROUND_COLOR,
  TABLE_DATA_TITLE_FONT_COLOR,
  TABLE_DATA_TITLE_FONT_SIZE,
  TABLE_DATA_TITLE_FONT_TYPE,
  TABLE_DATA_TITLE_HEIGHT,
  TABLE_DATA_Y_NEW_PAGE,
  TABLE_HISTORY_FONT_SIZE,
  TABLE_HISTORY_FONT_TYPE,
  TABLE_INFORMATION_FONT_COLOR,
  TABLE_INFORMATION_FONT_SIZE,
  TABLE_INFORMATION_FONT_TYPE,
  TABLE_INFORMATION_LEFT_COLUMNS_WIDTH,
  TABLE_INFORMATION_RIGHT_COLUMNS_WIDTH,
  TABLE_INFORMATION_VESSEL_FONT_TYPE,
  TABLE_INFORMATION_WIDTH,
  TABLE_INFORMATION_Y_START,
  TABLE_NAVIGATION_COLUMN_DETAILS_WIDTH,
  TABLE_NAVIGATION_COLUMN_POSITION_WIDTH,
  TABLES_DATA_VERTICAL_DISTANCE,
  TABLES_INFORMATION_DISTANCE,
  UNDEFINED_FONT_TYPE,
};
