import jsPDF from 'jspdf';
import autoTable, { applyPlugin, CellData, RowInput } from 'jspdf-autotable';

import { linesAsIndentedString } from '#/components/protocol/ProtocolUIConfigDetails';

import { checkForNewPage, drawLineThrough, drawTableBorder, writeTableTitle } from './helpers';
import * as style from './style';
import { ProtocolHistoryForPdfTable, RecordsForPDFTable } from './types';

applyPlugin(jsPDF);

export const insertRecordsTable = (
  doc: jsPDF,
  dataForOneDay: RecordsForPDFTable[],
  cursorPosition: { x: number; y: number },
  title: string,
  onlyTimeColumn: boolean,
): { x: number; y: number } => {
  let pageCounterOthers = 1;
  cursorPosition.y = checkForNewPage(doc, cursorPosition.y);
  writeTableTitle(doc, { y: cursorPosition.y + style.TABLES_DATA_VERTICAL_DISTANCE, title });
  if (dataForOneDay.length) {
    const indexMap: Record<number, number> = {};
    const historyRowMap: Record<number, ProtocolHistoryForPdfTable[]> = {};
    const tableHeightRowMap: Record<number, number> = {};
    let rowIndex = 0;
    autoTable(doc, {
      head: [
        [
          `${onlyTimeColumn ? 'Time' : 'Date and time'}${import.meta.env.VITE_SMT_HANDLING_ENABLED ? ' (SMT)' : ''}`,
          'Type',
          'Content Type',
          'Details',
          'Author (username | title)',
          'Approval\n2nd / 1st',
        ],
      ],
      body: dataForOneDay
        .map((protocol, protocolIndex) => {
          function includeHistory(rows: RowInput[]): RowInput[] {
            // add a placeholder row for displaying history of a record. (only if the record has a history)
            if (protocol.history && protocol.history.length > 0) {
              // historyRowMap: Identifier for row in the records table that is to be reserved for the history
              historyRowMap[rowIndex] = protocol.history;

              rows.push([{ content: '', styles: { cellPadding: 0 } }]);
              rowIndex = rowIndex + 1;
            }
            return rows;
          }

          const rowSpan = typeof protocol.details === 'string' ? 1 : protocol.details.length;
          const baseRow: RowInput = [
            { content: protocol.time, rowSpan },
            { content: protocol.type, rowSpan },
            '',
            '',
            { content: protocol.author, rowSpan },
            { content: `${protocol.secondApproval} / ${protocol.firstApproval}`, rowSpan },
          ];
          if (typeof protocol.details === 'string') {
            baseRow[3] = protocol.details;
            indexMap[rowIndex++] = protocolIndex;
            return includeHistory([baseRow]);
          }
          if (protocol.details.length === 0) {
            indexMap[rowIndex++] = protocolIndex;
            return includeHistory([baseRow]);
          }
          const rows: RowInput[] = protocol.details.reduce<RowInput[]>((previousRows, details, index) => {
            const indexOffset = index === 0 ? 0 : -2;
            const row = [...baseRow.slice(-indexOffset, index === 0 ? undefined : 4)];
            row[indexOffset + 2] = details.contentType;
            row[indexOffset + 3] = linesAsIndentedString(details.details);
            indexMap[rowIndex++] = protocolIndex;
            return [...previousRows, row];
          }, []);

          return includeHistory(rows);
        })
        .reduce((allRows, rows) => [...allRows, ...rows], []),
      columnStyles: {
        0: {
          cellWidth: style.TABLE_DATA_COLUMN_TIME_WIDTH + (onlyTimeColumn ? 0 : style.TABLE_DATA_COLUMN_DATE_WIDTH),
        },
        1: { cellWidth: style.TABLE_DATA_COLUMN_TYPE_WIDTH },
        2: { cellWidth: style.TABLE_DATA_COLUMN_CATEGORY_WIDTH },
        3: {
          cellWidth: style.TABLE_DATA_COLUMN_DETAILS_WIDTH - (onlyTimeColumn ? 0 : style.TABLE_DATA_COLUMN_DATE_WIDTH),
        },
        4: { cellWidth: style.TABLE_DATA_COLUMN_AUTHOR_WIDTH },
        6: { cellWidth: style.TABLE_DATA_COLUMN_APPROVAL_WIDTH },
      },
      didDrawPage: (data) => {
        data.table.settings.margin = {
          ...data.table.settings.margin,
          top: style.TABLE_DATA_Y_NEW_PAGE + style.TABLE_DATA_TITLE_HEIGHT,
        };
        if (pageCounterOthers > 1) {
          writeTableTitle(doc, { y: style.TABLE_DATA_Y_NEW_PAGE, title });
        }
        pageCounterOthers++;
      },
      willDrawCell: (data) => {
        // Before drawing a cell it checks whether the current row (on which the cursor currently is) is supposed to be for the record's history
        if (data.cell.section === 'body' && historyRowMap[data.row.index] && historyRowMap[data.row.index].length > 0) {
          autoTable(doc, {
            head: [
              [
                `History Timestamp${import.meta.env.VITE_SMT_HANDLING_ENABLED ? ' (UTC)' : ''}`,
                'Content Type',
                'Changes',
                'Changed By',
                'Reason For Change',
              ],
            ], // TODO: translate
            body: historyRowMap[data.row.index].flatMap<RowInput>((history) =>
              history.changes.map<RowInput>((change, index) => {
                const historyRow: RowInput = [
                  { content: change.contentType },
                  {
                    content: change.details
                      .map(
                        (cur) =>
                          `"${cur.changedField}" changed from "${cur.changeFrom || '--'}" to "${cur.changeTo || '--'}"`,
                      )
                      .join('\n'),
                  },
                ];
                if (index === 0) {
                  historyRow.unshift({
                    content: history.timestamp,
                    rowSpan: history.changes.length,
                  });
                  historyRow.push(
                    { content: history.changedBy, rowSpan: history.changes.length },
                    { content: history.reasonForChange, rowSpan: history.changes.length },
                  );
                }
                return historyRow;
              }),
            ),
            startY: cursorPosition.y,
            margin: {
              left: data.cell.x + 5,
              bottom: style.TABLE_DATA_MARGIN_BOTTOM,
              // margin top set conditionally because if the history table is in the first row of the page it gets behind the page header "Records"
              // can't set the margin as static because if it is not the first row of the page then again it would have the margin with it's parent record
              top:
                cursorPosition.y > style.TABLE_DATA_TITLE_HEIGHT + style.TABLE_DATA_TITLE_HEIGHT
                  ? style.TABLE_DATA_TITLE_HEIGHT + style.TABLE_DATA_TITLE_HEIGHT + 18
                  : cursorPosition.y,
            },
            tableWidth: data.cell.width - 10,
            pageBreak: 'auto',
            theme: 'grid',
            styles: {
              fontSize: style.TABLE_HISTORY_FONT_SIZE,
              cellPadding: 1,
              font: style.TABLE_HISTORY_FONT_TYPE,
              textColor: style.TABLE_DATA_CONTENT_FONT_COLOR,
            },
            rowPageBreak: 'auto',
            headStyles: {
              fillColor: style.TABLE_DATA_TITLE_BACKGROUND_COLOR,
              font: style.TABLE_DATA_HEADER_FONT_TYPE,
              textColor: style.TABLE_DATA_CONTENT_FONT_COLOR,
            },
            didDrawPage: (histData) => {
              // store the heights of each history table with its row index
              tableHeightRowMap[data.row.index] = (histData.cursor?.y || 0) - histData.settings.startY;
            },
          });
        }
      },
      didParseCell: (data) => {
        // once the cell content is processed we need to adjust the width of the cell to be able to contain the table. This is done here.
        if (data.cell.section === 'body' && data.row.index !== -1 && historyRowMap[data.row.index]) {
          data.cell.colSpan = 6;
        }
      },
      didDrawCell: (data) => {
        if (!historyRowMap[data.row.index]) {
          drawTableBorder(doc, {
            x: data.cell.x,
            y: data.cell.y,
            width: data.cell.width,
            color: style.TABLE_DATA_BORDER_COLOR,
          });
        }
        if (data.cell.section === 'body' && data.row.index !== -1) {
          if (dataForOneDay[indexMap[data.row.index]]?.deleted) {
            drawLineThrough(doc, data.cell as CellData);
          }
          if (historyRowMap[data.row.index]) {
            // row height assigned here. The height of each row of history table is 4.83986.
            data.row.height = tableHeightRowMap[data.row.index] + 2; // +2 for a bit of margin at the bottom. remove if not required
          }
        }
        cursorPosition = data.cursor as { x: number; y: number };
      },
      headStyles: {
        textColor: style.TABLE_DATA_HEADER_FONT_COLOR,
        fontSize: style.TABLE_DATA_HEADER_FONT_SIZE,
        font: style.TABLE_DATA_HEADER_FONT_TYPE,
      },
      margin: { left: style.CONTENT_MARGIN_LEFT, bottom: style.TABLE_DATA_MARGIN_BOTTOM },
      tableWidth: style.DOCUMENT_WIDTH - style.CONTENT_MARGIN_X,
      theme: 'plain',
      startY: cursorPosition.y + style.TABLES_DATA_VERTICAL_DISTANCE + style.TABLE_DATA_TITLE_HEIGHT,
      styles: {
        font: style.TABLE_DATA_CONTENT_FONT_TYPE,
        fontSize: style.TABLE_DATA_CONTENT_FONT_SIZE,
        textColor: style.TABLE_DATA_CONTENT_FONT_COLOR,
      },
      pageBreak: 'auto',
      rowPageBreak: 'auto',
    });
  } else {
    doc.setFontSize(style.TABLE_DATA_NOT_AVAILABLE_FONT_SIZE);
    doc.setFont(style.TABLE_DATA_NOT_AVAILABLE_FONT_TYPE);
    doc.setTextColor(style.TABLE_DATA_NOT_AVAILABLE_FONT_COLOR);
    const y =
      cursorPosition.y +
      style.TABLES_DATA_VERTICAL_DISTANCE +
      style.TABLE_DATA_TITLE_HEIGHT +
      style.TABLE_DATA_NOT_AVAILABLE_TEXT_MARGIN_TOP;
    doc.text('No records available', style.DOCUMENT_WIDTH / 2, y, {
      align: 'center',
      baseline: 'middle',
    });
    cursorPosition = {
      x: 0,
      y,
    };
  }
  return cursorPosition;
};
